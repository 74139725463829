import pia from "./pia-helper";

document.addEventListener("turbo:load", () => {
  if (!pia.selectFirst(".user_privileges-index")) {
    return;
  }
  pia.addListener(".privilege-tooltip-anchor", "click", (event) => {
    const tooltip = event.target.parentElement.previousElementSibling;
    pia.showElement(tooltip);
    var bounding = tooltip.getBoundingClientRect();

    if (bounding.bottom > window.innerHeight) {
      tooltip.style.marginTop = `-${bounding.height}px`;
    } else {
      tooltip.style.marginTop = "-32px";
    }

    if (bounding.right > window.innerWidth) {
      tooltip.style.marginLeft = `-${8 + bounding.width}px`;
    } else {
      tooltip.style.marginLeft = "216px";
    }
  });

  pia.addListener(".privilege-tooltip-anchor", "mouseout", (event) => {
    pia.hideElement(event.target.parentElement.previousElementSibling);
  });
});
