import { application } from "./application";

import DesktopNavigationController from "./desktop_navigation_controller";
application.register("desktop-navigation", DesktopNavigationController);

import MobileNavigationController from "./mobile_navigations_controller";
application.register("mobile-navigation", MobileNavigationController);

import TomSelectController from "./tom_select_controller";
application.register("tom-select", TomSelectController);
