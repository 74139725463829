import tablesort from "tablesort";
import pia from "./pia-helper";

document.addEventListener("turbo:load", () => {
  if (pia.selectFirst(".business_optimization_rules-index")) {
    tablesort(pia.selectFirst("#hard_optimization_rule_table"));
    tablesort(pia.selectFirst("#soft_optimization_rule_table"));
  }
  if (
    pia.selectFirst(
      ".business_optimization_rules-edit, .business_optimization_rules-update",
    )
  ) {
    pia.addListener("#business_optimization_rule_qs_type", "change", (e) => {
      if (e.target.value == "hc") {
        pia.selectFirst("#business_optimization_rule_penalty_value").value =
          "1";
      }
    });
  }
});
